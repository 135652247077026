/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as s } from "../../chunks/tslib.es6.js";
import e from "../Accessor.js";
import { makeHandle as t } from "../handleUtils.js";
import { watch as i, when as n, on as d, sync as a } from "../reactiveUtils.js";
import { schedule as r } from "../scheduling.js";
import { property as o } from "../accessorSupport/decorators/property.js";
import { subclass as l } from "../accessorSupport/decorators/subclass.js";
let h = class extends e {
  constructor() {
    super(...arguments), this.updating = !1, this._handleId = 0, this._scheduleHandleId = 0, this._pendingPromises = new Set();
  }
  destroy() {
    this.removeAll();
  }
  add(s, e, t = {}) {
    return this._installWatch(s, e, t, i);
  }
  addWhen(s, e, t = {}) {
    return this._installWatch(s, e, t, n);
  }
  addOnCollectionChange(s, e, {
    initial: i = !1,
    final: n = !1
  } = {}) {
    const r = ++this._handleId;
    return this.addHandles([d(s, "after-changes", this._createSyncUpdatingCallback(), a), d(s, "change", e, {
      onListenerAdd: i ? s => e({
        added: s.toArray(),
        removed: []
      }) : void 0,
      onListenerRemove: n ? s => e({
        added: [],
        removed: s.toArray()
      }) : void 0
    })], r), t(() => this.removeHandles(r));
  }
  addPromise(s) {
    if (null == s) return s;
    const e = ++this._handleId;
    this.addHandles(t(() => {
      this._pendingPromises.delete(s) && (0 !== this._pendingPromises.size || this.hasHandles(c) || this._set("updating", !1));
    }), e), this._pendingPromises.add(s), this._set("updating", !0);
    const i = () => this.removeHandles(e);
    return s.then(i, i), s;
  }
  removeAll() {
    this._pendingPromises.clear(), this.removeAllHandles(), this._set("updating", !1);
  }
  _installWatch(s, e, i = {}, n) {
    const d = ++this._handleId;
    i.sync || this._installSyncUpdatingWatch(s, d);
    const a = n(s, e, i);
    return this.addHandles(a, d), t(() => this.removeHandles(d));
  }
  _installSyncUpdatingWatch(s, e) {
    const t = this._createSyncUpdatingCallback(),
      n = i(s, t, {
        sync: !0,
        equals: () => !1
      });
    return this.addHandles(n, e), n;
  }
  _createSyncUpdatingCallback() {
    return () => {
      this.removeHandles(c), ++this._scheduleHandleId;
      const s = this._scheduleHandleId;
      this._get("updating") || this._set("updating", !0), this.addHandles(r(() => {
        s === this._scheduleHandleId && (this._set("updating", this._pendingPromises.size > 0), this.removeHandles(c));
      }), c);
    };
  }
};
s([o({
  readOnly: !0
})], h.prototype, "updating", void 0), h = s([l("esri.core.support.UpdatingHandles")], h);
const c = -42;
export { h as UpdatingHandles };